@font-face {
    font-family: 'DM Serif Display';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/dm-serif-display-v10-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/dm-serif-display-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/dm-serif-display-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/dm-serif-display-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/dm-serif-display-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/dm-serif-display-v10-latin-regular.svg#DMSerifDisplay') format('svg'); /* Legacy iOS */
}

.PublicRoute {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 100px 0;
}

.main-container{
  flex-grow: 1;
}

.errorMsg {
  color:red!important;
  font-size:16px;
  font-weight:Bold;
}

.register__orderUl {
  border: 1px solid #f1f1f1;
  margin-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 3px;
  overflow: hidden;
  background: #fafafa;
}
.register__orderLi{
  height:45px;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
}
.homePage{
    overflow: hidden;
}

.pricingSelection {
    position: relative;
}

.homeIconImage{
    width: 80px;
    float: left;
    margin-top: 35px;
}
.hero-image{
    margin-top: 50px;
}
@media screen and (max-width: 600px) {
    .homeIconImage{
        display: none;
    }
}
